<template>
  <div class="m-5 px-2">
    <div>
      <h1 class="title">{{viewName}}</h1>
    </div>
    <div class="mt-5">
      <div v-if="waiting">
        <span class="icon is-medium is-size-4">
          <i class="fas fa-spinner fa-pulse"></i>
        </span>
      </div>
      <div v-else>
        <table class="table is-fullwidth is-hoverable is-striped mt-4">
          <thead>
            <tr>
              <th class="is-clickable" @click="changeSortOption('id')">
                <span>Id</span>
                <span class="icon" v-if="sortOption.field == 'id'">
                  <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                </span>
              </th>
              <th class="is-clickable" @click="changeSortOption('poNum')">
                <span>PO#</span>
                <span class="icon" v-if="sortOption.field == 'poNum'">
                  <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                </span>
              </th>
              <th class="is-clickable" @click="changeSortOption('itemSku')">
                <span>Sku</span>
                <span class="icon" v-if="sortOption.field == 'itemSku'">
                  <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                </span>
              </th>
              <th class="is-clickable" @click="changeSortOption('serialNum')">
                <span>Serial#</span>
                <span class="icon" v-if="sortOption.field == 'serialNum'">
                  <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                </span>
              </th>
              <th class="is-clickable" @click="changeSortOption('productClass')">
                <span>Class</span>
                <span class="icon" v-if="sortOption.field == 'productClass'">
                  <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                </span>
              </th>
              <th class="is-clickable" @click="changeSortOption('itemCondition')">
                <span>Condition</span>
                <span class="icon" v-if="sortOption.field == 'itemCondition'">
                  <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                </span>
              </th>
              <th class="is-clickable" @click="changeSortOption('shippedFrom')">
                <span>Shipped at</span>
                <span class="icon" v-if="sortOption.field == 'shippedFrom'">
                  <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                </span>
              </th>
              <th class="is-clickable" @click="changeSortOption('warehouse')">
                <span>Received at</span>
                <span class="icon" v-if="sortOption.field == 'warehouse'">
                  <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                </span>
              </th>
              <th class="is-clickable" @click="changeSortOption('createdAt')">
                <span>Returned at</span>
                <span class="icon" v-if="sortOption.field == 'createdAt'">
                  <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                </span>
              </th>
              <th>Picture</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in showingItems">
              <td>{{item.id}}</td>
              <td>{{item.poNum}}</td>
              <td>{{item.itemSku}}</td>
              <td>{{item.serialNum}}</td>
              <td>{{item.productClass}}</td>
              <td>{{item.itemCondition}}</td>
              <td>{{item.shippedFrom}}</td>
              <td>{{item.warehouse}}</td>
              <td>{{item.createdAtLabel}}</td>
              <td>
                <div v-if="imageMap[item.id] && imageMap[item.id].attachmentId">
                  <div v-if="imageMap[item.id].loading">
                    <span class="icon is-medium is-size-4">
                      <i class="fas fa-spinner fa-pulse"></i>
                    </span>
                  </div>
                  <div v-else>
                    <div v-if="imageMap[item.id].url">
                      <figure class="image is-clickable" @click="openImageModal(imageMap[item.id].url)">
                        <img style="max-height:100px;max-width:100px;height:auto;width:auto;" :src="imageMap[item.id].url" />
                      </figure>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div v-if="error" class="notification is-danger is-light">
        <button class="delete" @click="error=''"></button>
        {{error}}
      </div>
      <div class="modal" :class="{'is-active': imageModal.opened}">
        <div class="modal-background"></div>
        <div class="modal-content">
          <p class="image">
            <img :src="imageModal.url" alt="">
          </p>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="imageModal.opened = false"></button>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from 'dateformat'

export default {
  name: 'ReturnedItemsView',
  data () {
    return {
      error: '',
      waiting: false,
      items: [],
      imageMap: {},
      imageModal: {
        opened: false,
        url: ''
      },
      sortOption: {
        field: 'createdAt',
        asc: false
      },
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    token () {
      return this.$store.state.user.token
    },
    showingItems () {
      var vm = this
      var sortedItems = this.items.sort((a, b) => {
        var va = a[vm.sortOption.field]
        var vb = b[vm.sortOption.field]
        if (vm.sortOption.field == 'id' || vm.sortOption.field == 'createdAt') {
          return vm.sortOption.asc ? va - vb : vb - va
        }
        return vm.sortOption.asc ? va.localeCompare(vb) : vb.localeCompare(va)
      })
      return sortedItems.map(item => {
        var i = JSON.parse(JSON.stringify(item))
        i.createdAtLabel = dateFormat(new Date(i.createdAt * 1000), 'yyyy-mm-dd')
        return i
      })
    },
    viewId () {
      return this.$route.params.viewId
    },
    viewCode () {
      return this.$route.params.viewCode
    },
    viewName () {
      return this.$route.params.viewName
    },
  },
  methods: {
    getReturnedItemsView () {
      this.waiting = true
      var message = {viewId: this.viewId, viewCode: this.viewCode}
      this.$http.post(this.server + '/myapp/returns-view-get-returned-items/', message).then(resp => {
        this.items = resp.body
        this.getImageMap()
        this.waiting = false
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
    getImageMap () {
      var imageMap = {}
      for (var item of this.items) {
        var attachments = item.attachments.split(',')
        var attachmentId = attachments[2]
        imageMap[item.id] = {itemId: item.id, attachmentId: attachmentId, url: '', loading: Boolean(attachmentId)}
      }
      this.imageMap = imageMap

      for (var item of this.items) {
        var imageObj = imageMap[item.id]
        if (!imageObj.attachmentId) {
          continue
        }
        var message = {
          returnedItemId: item.id,
          attachmentId: imageObj.attachmentId,
          viewId: this.viewId,
          viewCode: this.viewCode,
        }
        this.$http.post(this.server + '/myapp/returns-view-get-attachment-url/', message).then(resp => {
          this.imageMap[resp.body.returnedItemId].url = resp.body.url
          this.imageMap[resp.body.returnedItemId].loading = false
        }, err => {
          this.error = err.body
        })
      }
    },
    openImageModal (url) {
      this.imageModal.url = url
      this.imageModal.opened = true
    },
    changeSortOption (field) {
      if (this.sortOption.field == field) {
        this.sortOption.asc = !this.sortOption.asc
      } else {
        this.sortOption.field = field
        this.sortOption.asc = true
      }
    },
  },
  mounted () {
    this.$nextTick(function () {
      this.getReturnedItemsView()
    })
  },
}
</script>

<style scoped>
.image-in-table {
  max-height:100px;
  max-width:100px;
  height:auto;
  width:auto;
}
</style>
    